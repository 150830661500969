















































import {
    defineComponent,
    ref,
    useContext
} from '@nuxtjs/composition-api'

import SuitcaseIcon from '@/assets/img/suitcase.svg'
import ChevronIcon from '@/assets/img/chevron.svg'
import QuestionIcon from '@/assets/img/question-circle.svg'

import {
    selectedCabinetId,
    selectedCabinetName,
    cabinetTypesOptions,
    changeCurrentCabinet
} from '@/composables/cabinet-type'

export default defineComponent({
    components: {
        SuitcaseIcon,
        ChevronIcon,
        QuestionIcon
    },
    setup: () => {
        const context = useContext()
        const showAllCabinets = ref(false)

        const changeCurrentCabinetId = async (cabinetId: string) => {
            await changeCurrentCabinet(cabinetId, context)
        }

        return {
            cabinetTypesOptions,
            selectedCabinetId,
            selectedCabinetName,
            changeCurrentCabinetId,
            showAllCabinets
        }
    }
})
