var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.selectedCabinetId ? _c('div', {
    staticClass: "catalog-cabinet-types"
  }, [_c('div', {
    staticClass: "catalog-cabinet-types__selected-cabinet",
    on: {
      "click": function ($event) {
        _vm.showAllCabinets = !_vm.showAllCabinets;
      }
    }
  }, [_c('div', {
    staticClass: "catalog-cabinet-types__selected-cabinet-name"
  }, [_c('suitcase-icon', {
    staticClass: "catalog-cabinet-types__icon-suit"
  }), _vm._v(" "), _c('div', {
    staticClass: "catalog-cabinet-types__selected-cabinet-text"
  }, [_vm._v("\n                " + _vm._s(_vm.selectedCabinetName) + "\n            ")]), _vm._v(" "), _c('chevron-icon', {
    staticClass: "catalog-cabinet-types__icon-chevron"
  })], 1), _vm._v(" "), _c('app-tooltip', {
    attrs: {
      "hoverable": "",
      "placement": "bottom"
    },
    scopedSlots: _vm._u([{
      key: "tooltip",
      fn: function () {
        return [_vm._v("\n                Смена кабинета по умолчанию влияет на разное налоговое\n                обложение при расчёте вознаграждения по заявкам\n            ")];
      },
      proxy: true
    }], null, false, 2341016291)
  }, [_c('question-icon', {
    staticClass: "catalog-cabinet-types__icon-question",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    class: ['catalog-cabinet-types__all-cabinets', {
      'catalog-cabinet-types__all-cabinets--opened': _vm.showAllCabinets
    }]
  }, [_c('app-radio', {
    attrs: {
      "name": "cabinetTypesMobile",
      "font-size": "14px",
      "items": _vm.cabinetTypesOptions
    },
    on: {
      "input": _vm.changeCurrentCabinetId
    },
    model: {
      value: _vm.selectedCabinetId,
      callback: function ($$v) {
        _vm.selectedCabinetId = $$v;
      },
      expression: "selectedCabinetId"
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }