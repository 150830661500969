var render = function () {
  var _vm$currentDevice;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "the-mobile-profile"
  }, [((_vm$currentDevice = _vm.currentDevice) === null || _vm$currentDevice === void 0 ? void 0 : _vm$currentDevice.alias) === 'mobile' ? _c('catalog-profile', {
    staticClass: "the-mobile-profile__profile",
    attrs: {
      "profile-link": "/profile"
    }
  }) : _vm._e(), _vm._v(" "), _c('app-divider', {
    staticClass: "the-mobile-profile__divider"
  }), _vm._v(" "), _c('mobile-catalog-navigation', {
    attrs: {
      "catalog-items": _vm.catalogItems,
      "navigation": _vm.navigation,
      "placement": "right",
      "aside": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "the-mobile-profile__notifications"
  }, [_c('header-notifications-button', {
    nativeOn: {
      "click": function ($event) {
        return _vm.closeAside.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "text",
      fn: function () {
        return [_vm._v("\n                Уведомления\n            ")];
      },
      proxy: true
    }])
  }), _vm._v(" "), _vm.channelId ? _c('header-messages-button', {
    attrs: {
      "channel-id": _vm.channelId
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.closeAside.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "text",
      fn: function () {
        return [_vm._v("\n                Сообщения\n            ")];
      },
      proxy: true
    }], null, false, 1899145510)
  }) : _vm._e(), _vm._v(" "), _c('catalog-cabinet-types')], 1), _vm._v(" "), _c('app-divider', {
    staticClass: "the-mobile-profile__divider"
  }), _vm._v(" "), _c('catalog-profile-section', {
    staticClass: "the-mobile-profile__item",
    attrs: {
      "placement": "right",
      "aside": ""
    }
  }), _vm._v(" "), _c('catalog-item', {
    staticClass: "the-mobile-profile__logout",
    attrs: {
      "shadow": ""
    },
    on: {
      "on-click": _vm.logout
    }
  }, [_c('logout-icon', {
    staticClass: "the-mobile-profile__icon",
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  }), _vm._v("\n        Выход\n    ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }