import { render, staticRenderFns } from "./-HeaderProfile.vue?vue&type=template&id=7b797df2&scoped=true&"
import script from "./-HeaderProfile.vue?vue&type=script&lang=ts&"
export * from "./-HeaderProfile.vue?vue&type=script&lang=ts&"
import style0 from "./-HeaderProfile.vue?vue&type=style&index=0&id=7b797df2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b797df2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppAvatar: require('/var/www/app/src/components/ui/AppAvatar.vue').default,AppCopy: require('/var/www/app/src/components/ui/AppCopy.vue').default,AppHint: require('/var/www/app/src/components/ui/AppHint.vue').default,AppTooltip: require('/var/www/app/src/components/ui/AppTooltip.vue').default})
