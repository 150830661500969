/* eslint-disable import/no-extraneous-dependencies */
import { Components as Customer } from '@/types/client.customer'
import { Context } from '@nuxt/types'
import { ExtendedCabinetType } from '@/composables/cabinet-type'
import { Merge } from '@/composables/joins'
import { sortByPriority } from '@/composables/sorting'

export type ExtendedCabinetStatus =
    Customer.Schemas.ExecutorCabinetStatus |
    Customer.Schemas.SelfEmployedRequisitesStatus

export type ExecutorCabinet = Merge<
    Customer.Schemas.ExecutorCabinet,
    {
        type?: ExtendedCabinetType
        status: ExtendedCabinetStatus
        name?: string,
        accounts: Customer.Schemas.CabinetAccountItem[]
    }
>

export type LegalEntityKycDocuments = Merge<
    Customer.Schemas.LegalEntityKycDocuments,
    Customer.Schemas.IndividualEntrepreneurKycDocuments
> | undefined

export const useCabinets = (context: unknown) => {
    const { $apiCustomer } = context as Context

    const createNaturalPersonCabinet = async () => {
        const { data } = await $apiCustomer.executorCabinetsCreate(null, {
            type: 'NaturalPerson'
        })

        return data
    }

    const fetchSelfEmployedStatus = async (naturalPersonId: string) => {
        try {
            const {
                data
            } = await $apiCustomer.naturalPersonExecutorCabinetsGetSelfEmployedWithdrawalRequisites(
                naturalPersonId
            )

            return data.requisites?.status
        } catch (error) {
            console.error(error)

            return 'Undefined'
        }
    }

    const fetchCabinets = async () => {
        try {
            const {
                data: { items = [] }
            } = await $apiCustomer.executorCabinetsGetUserCabinetsByUserId({
                Limit: 1000
            })

            const cabinets: ExecutorCabinet[] = items

            let naturalPersonCabinet = cabinets.find(
                cabinet => cabinet.type === 'NaturalPerson'
            )

            if (!naturalPersonCabinet) {
                naturalPersonCabinet = await createNaturalPersonCabinet()
                cabinets.push(naturalPersonCabinet)
            }

            const SelfEmployedStatus = await fetchSelfEmployedStatus(
                naturalPersonCabinet?.id as string
            )

            const balance = naturalPersonCabinet.accounts?.find(
                account => account.type === 'SelfEmployed'
            )?.balance || {}

            const isCanWithdraw = naturalPersonCabinet.accounts?.find(
                account => account.type === 'SelfEmployed'
            ) as Customer.Schemas.CabinetAccountItem

            cabinets.push({
                ...naturalPersonCabinet,
                type: 'SelfEmployed',
                status: SelfEmployedStatus,
                accounts: [ {
                    type: 'SelfEmployed',
                    balance,
                    canWithdraw: isCanWithdraw.canWithdraw as Customer.Schemas.CabinetAccountItem['canWithdraw']
                } ]
            })

            const selfEmployedAccountIndex = naturalPersonCabinet.accounts?.findIndex(
                account => account.type === 'SelfEmployed'
            ) || -1

            if (selfEmployedAccountIndex !== -1) {
                naturalPersonCabinet.accounts?.splice(selfEmployedAccountIndex, 1)
            }

            cabinets.sort((a, b) =>
                sortByPriority(a.type, b.type, {
                    NaturalPerson: 1,
                    SelfEmployed: 2,
                    IndividualEntrepreneur: 2,
                    LegalEntity: 3,
                    Unknown: 4
                })
            )

            const filledCabinets = await Promise.all(
                cabinets.map(async (cabinet) => {
                    if (!cabinet.id || !cabinet.type || cabinet.type === 'Unknown') return cabinet

                    if (cabinet.type === 'SelfEmployed') {
                        cabinet.name = 'Самозанятый'

                        return cabinet
                    }

                    if (cabinet.type === 'NaturalPerson') {
                        cabinet.name = 'Физическое лицо'

                        return cabinet
                    }

                    const getKycDocuments = {
                        LegalEntity: $apiCustomer.legalEntityExecutorCabinetsGetKycDocuments,
                        IndividualEntrepreneur:
                        $apiCustomer.individualEntrepreneurCabinetsGetKycDocuments
                    }[cabinet.type]

                    try {
                        const response = await getKycDocuments(cabinet.id)
                        const documents: LegalEntityKycDocuments = response.data.kycDocuments

                        if (cabinet.type === 'LegalEntity') {
                            cabinet.name = `ООО ${documents?.legalFullName}`
                        }

                        if (cabinet.type === 'IndividualEntrepreneur') {
                            const { lastName = '', firstName = '', middleName = '' } = documents?.passport || {}

                            cabinet.name = `ИП ${[ lastName, firstName, middleName ].join(' ')}`
                        }

                        return cabinet
                    } catch (error) {
                        console.error(error)

                        return cabinet
                    }
                })
            )

            return filledCabinets
        } catch (error) {
            console.error(error)

            return []
        }
    }

    const needShowAboutIncomeModal = () => {
        if (navigator.cookieEnabled) {
            const results = document.cookie.match(/showCabinetAboutIncomeModal=(.+?)(;|$)/);
            if (!results) {
                document.cookie = "showCabinetAboutIncomeModal=true;max-age=604800"
                return true
            }

            return false
        }

        return false
    }

    return {
        createNaturalPersonCabinet,
        fetchSelfEmployedStatus,
        fetchCabinets,
        needShowAboutIncomeModal
    }
}
