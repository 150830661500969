var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cabinet-type"
  }, [_vm.selectedCabinetId ? _c('app-tooltip', {
    staticClass: "cabinet-type__tooltip",
    attrs: {
      "placement": "bottom",
      "hoverable": "",
      "offset": 20
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (defaultScope) {
        return [_c('div', {
          staticClass: "cabinet-type__tooltip-title"
        }, [_c('suitcase-icon', {
          staticClass: "cabinet-type__suitcase"
        }), _vm._v("\n                " + _vm._s(_vm.selectedCabinetName) + "\n                "), _c('chevron-icon', {
          staticClass: "cabinet-type__chevron"
        })], 1)];
      }
    }, {
      key: "tooltip",
      fn: function () {
        return [_c('div', [_c('div', {
          staticClass: "cabinet-type__menu"
        }, [_c('div', {
          staticClass: "cabinet-type__menu-title"
        }, [_vm._v("\n                        Выбранный режим\n                    ")]), _vm._v(" "), _c('div', {
          staticClass: "cabinet-type__menu-description"
        }, [_vm._v("\n                        Выбирая режим в качестве основного, создание заявок и расчет дохода будет производится в нем.\n                    ")]), _vm._v(" "), _c('app-radio', {
          attrs: {
            "name": "cabinetTypesHeader",
            "items": _vm.cabinetTypesOptions
          },
          on: {
            "input": _vm.changeCurrentCabinetId
          },
          model: {
            value: _vm.selectedCabinetId,
            callback: function ($$v) {
              _vm.selectedCabinetId = $$v;
            },
            expression: "selectedCabinetId"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 3040484215)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }