



































import {
    computed,
    defineComponent,
    onMounted,
    ref,
    useContext
} from '@nuxtjs/composition-api'

import { userFullName, user } from '@/composables/user'
import { httpLinkRegExp } from '@/composables/regexps'
import { ExecutorCabinet } from '@/composables/cabinets'
import { sortByPriority } from '@/composables/sorting'

import CatalogBalance from './-CatalogBalance.vue'

export default defineComponent({
    components: {
        CatalogBalance
    },
    props: {
        profileLink: {
            type: String,
            default: '/profile'
        }
    },
    setup: (props) => {
        const { $apiCustomer } = useContext()
        const availableBalance = ref(0)

        const linkType = computed(() => {
            if (httpLinkRegExp.test(props.profileLink || '')) {
                return 'a'
            }

            return 'nuxt-link'
        })

        const createNaturalPersonCabinet = async () => {
            const { data } = await $apiCustomer.executorCabinetsCreate(null, {
                type: 'NaturalPerson'
            })

            return data
        }

        const getBalance = async () => {
            const {
                data: { items = [] }
            } = await $apiCustomer.executorCabinetsGetUserCabinetsByUserId({
                Limit: 1000
            })

            const cabinets: ExecutorCabinet[] = items

            let naturalPersonCabinet = cabinets.find(
                cabinet => cabinet.type === 'NaturalPerson'
            )

            if (!naturalPersonCabinet) {
                naturalPersonCabinet = await createNaturalPersonCabinet()
                cabinets.push(naturalPersonCabinet)
            }

            const selfEmployedAccountIndex = naturalPersonCabinet.accounts?.findIndex(
                account => account.type === 'SelfEmployed'
            ) || -1

            if (selfEmployedAccountIndex !== -1) {
                naturalPersonCabinet.accounts?.splice(selfEmployedAccountIndex, 1)
            }

            cabinets.sort((a, b) =>
                sortByPriority(a.type, b.type, {
                    NaturalPerson: 1,
                    SelfEmployed: 2,
                    IndividualEntrepreneur: 2,
                    LegalEntity: 3,
                    Unknown: 4
                }))
            const balances = cabinets.reduce((total, cabinet) => {
                if (cabinet.type === 'SelfEmployed') return total

                cabinet.accounts?.forEach((account) => {
                    total.currentAmount += account.balance?.currentAmount || 0
                })

                return total
            }, {
                currentAmount: 0
            })
            availableBalance.value = balances.currentAmount
        }

        onMounted(async () => {
            await getBalance()
        })

        return {
            availableBalance,
            userFullName,
            linkType,
            user
        }
    }

})
