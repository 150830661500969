var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "catalog-balance"
  }, [_c('nuxt-link', {
    staticClass: "catalog-balance__available",
    attrs: {
      "to": "/cabinet"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.formattedAvailable)
    }
  }), _vm._v(" ₽\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }