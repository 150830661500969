import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _414e99b1 = () => interopDefault(import('../src/pages/customer/bonuses.vue' /* webpackChunkName: "pages/customer/bonuses" */))
const _61995118 = () => interopDefault(import('../src/pages/customer/bonuses/index.vue' /* webpackChunkName: "pages/customer/bonuses/index" */))
const _2ceb9202 = () => interopDefault(import('../src/pages/customer/cabinet/index.vue' /* webpackChunkName: "pages/customer/cabinet/index" */))
const _21787f42 = () => interopDefault(import('../src/pages/customer/courses/index.vue' /* webpackChunkName: "pages/customer/courses/index" */))
const _f6211a5e = () => interopDefault(import('../src/pages/customer/login-as-claim.vue' /* webpackChunkName: "pages/customer/login-as-claim" */))
const _458e8574 = () => interopDefault(import('../src/pages/customer/managers-chat/index.vue' /* webpackChunkName: "pages/customer/managers-chat/index" */))
const _45899c8f = () => interopDefault(import('../src/pages/customer/notifications/index.vue' /* webpackChunkName: "pages/customer/notifications/index" */))
const _f82a2aba = () => interopDefault(import('../src/pages/customer/offers.vue' /* webpackChunkName: "pages/customer/offers" */))
const _be10aa34 = () => interopDefault(import('../src/pages/customer/offers/index.vue' /* webpackChunkName: "pages/customer/offers/index" */))
const _33558196 = () => interopDefault(import('../src/pages/customer/offers/-OffersFilters/index.vue' /* webpackChunkName: "pages/customer/offers/-OffersFilters/index" */))
const _6398a77e = () => interopDefault(import('../src/pages/customer/offers/-OffersHintModal/index.vue' /* webpackChunkName: "pages/customer/offers/-OffersHintModal/index" */))
const _4f57c6b2 = () => interopDefault(import('../src/pages/customer/offers/-OffersMobileTable/index.vue' /* webpackChunkName: "pages/customer/offers/-OffersMobileTable/index" */))
const _40ce3948 = () => interopDefault(import('../src/pages/customer/offers/-OffersPeriod/index.vue' /* webpackChunkName: "pages/customer/offers/-OffersPeriod/index" */))
const _2c94e2d1 = () => interopDefault(import('../src/pages/customer/offers/_id/index.vue' /* webpackChunkName: "pages/customer/offers/_id/index" */))
const _bba7771a = () => interopDefault(import('../src/pages/customer/postbacks/index.vue' /* webpackChunkName: "pages/customer/postbacks/index" */))
const _8969a5e0 = () => interopDefault(import('../src/pages/customer/profile/index.vue' /* webpackChunkName: "pages/customer/profile/index" */))
const _8ed9cbd4 = () => interopDefault(import('../src/pages/customer/publications/index.vue' /* webpackChunkName: "pages/customer/publications/index" */))
const _93960714 = () => interopDefault(import('../src/pages/customer/starter/index.vue' /* webpackChunkName: "pages/customer/starter/index" */))
const _db40aba2 = () => interopDefault(import('../src/pages/customer/statistics.vue' /* webpackChunkName: "pages/customer/statistics" */))
const _86ecdd1c = () => interopDefault(import('../src/pages/customer/statistics/index.vue' /* webpackChunkName: "pages/customer/statistics/index" */))
const _5195bf60 = () => interopDefault(import('../src/pages/customer/statistics/-StatisticsColumns/index.vue' /* webpackChunkName: "pages/customer/statistics/-StatisticsColumns/index" */))
const _7a0e8404 = () => interopDefault(import('../src/pages/customer/statistics/-StatisticsFilters/index.vue' /* webpackChunkName: "pages/customer/statistics/-StatisticsFilters/index" */))
const _7227c0b6 = () => interopDefault(import('../src/pages/customer/statistics/-StatisticsGrouping/index.vue' /* webpackChunkName: "pages/customer/statistics/-StatisticsGrouping/index" */))
const _cc2f0c18 = () => interopDefault(import('../src/pages/customer/statistics/-StatisticsPeriod/index.vue' /* webpackChunkName: "pages/customer/statistics/-StatisticsPeriod/index" */))
const _0eaaf411 = () => interopDefault(import('../src/pages/customer/statistics/-StatisticsTable/index.vue' /* webpackChunkName: "pages/customer/statistics/-StatisticsTable/index" */))
const _2a3eb664 = () => interopDefault(import('../src/pages/customer/tickets/index.vue' /* webpackChunkName: "pages/customer/tickets/index" */))
const _690305ce = () => interopDefault(import('../src/pages/customer/auth/login.vue' /* webpackChunkName: "pages/customer/auth/login" */))
const _4d54cbb0 = () => interopDefault(import('../src/pages/customer/auth/success.vue' /* webpackChunkName: "pages/customer/auth/success" */))
const _6411c65e = () => interopDefault(import('../src/pages/customer/cabinet/-CabinetCloseWithdrawalModal/index.vue' /* webpackChunkName: "pages/customer/cabinet/-CabinetCloseWithdrawalModal/index" */))
const _409ef733 = () => interopDefault(import('../src/pages/customer/cabinet/-IndividualEntrepreneurForm/index.vue' /* webpackChunkName: "pages/customer/cabinet/-IndividualEntrepreneurForm/index" */))
const _35687a59 = () => interopDefault(import('../src/pages/customer/cabinet/-LegalEntityForm/index.vue' /* webpackChunkName: "pages/customer/cabinet/-LegalEntityForm/index" */))
const _6573d01f = () => interopDefault(import('../src/pages/customer/cabinet/natural-person/index.vue' /* webpackChunkName: "pages/customer/cabinet/natural-person/index" */))
const _6a10a97e = () => interopDefault(import('../src/pages/customer/cabinet/self-employed/index.vue' /* webpackChunkName: "pages/customer/cabinet/self-employed/index" */))
const _efc5fde4 = () => interopDefault(import('../src/pages/customer/cabinet/withdrawals/index.vue' /* webpackChunkName: "pages/customer/cabinet/withdrawals/index" */))
const _25ecc122 = () => interopDefault(import('../src/pages/customer/courses/categories/index.vue' /* webpackChunkName: "pages/customer/courses/categories/index" */))
const _5e118c2d = () => interopDefault(import('../src/pages/customer/request/fail/index.vue' /* webpackChunkName: "pages/customer/request/fail/index" */))
const _4ecad033 = () => interopDefault(import('../src/pages/customer/request/sucess/index.vue' /* webpackChunkName: "pages/customer/request/sucess/index" */))
const _13792a34 = () => interopDefault(import('../src/pages/customer/showcases/categories/index.vue' /* webpackChunkName: "pages/customer/showcases/categories/index" */))
const _28028dc2 = () => interopDefault(import('../src/pages/customer/tickets/new/index.vue' /* webpackChunkName: "pages/customer/tickets/new/index" */))
const _9b926ccc = () => interopDefault(import('../src/pages/customer/cabinet/withdrawals/act/precalculation/index.vue' /* webpackChunkName: "pages/customer/cabinet/withdrawals/act/precalculation/index" */))
const _7523ef88 = () => interopDefault(import('../src/pages/customer/cabinet/withdrawals/act/_id/index.vue' /* webpackChunkName: "pages/customer/cabinet/withdrawals/act/_id/index" */))
const _372ebd53 = () => interopDefault(import('../src/pages/customer/cabinet/new-withdrawal/_id/index.vue' /* webpackChunkName: "pages/customer/cabinet/new-withdrawal/_id/index" */))
const _e8bb0faa = () => interopDefault(import('../src/pages/customer/courses/categories/_categoryId/index.vue' /* webpackChunkName: "pages/customer/courses/categories/_categoryId/index" */))
const _587e1f38 = () => interopDefault(import('../src/pages/customer/showcases/categories/_categoryalias/index.vue' /* webpackChunkName: "pages/customer/showcases/categories/_categoryalias/index" */))
const _5e91ae1e = () => interopDefault(import('../src/pages/customer/showcases/categories/_categoryalias/subcategories/index.vue' /* webpackChunkName: "pages/customer/showcases/categories/_categoryalias/subcategories/index" */))
const _9fc3765a = () => interopDefault(import('../src/pages/customer/showcases/categories/_categoryalias/subcategories/_subcategoryalias/index.vue' /* webpackChunkName: "pages/customer/showcases/categories/_categoryalias/subcategories/_subcategoryalias/index" */))
const _03fefbf7 = () => interopDefault(import('../src/pages/customer/articles/_id/index.vue' /* webpackChunkName: "pages/customer/articles/_id/index" */))
const _db3c8b2c = () => interopDefault(import('../src/pages/customer/cabinet/_id/index.vue' /* webpackChunkName: "pages/customer/cabinet/_id/index" */))
const _b910d86c = () => interopDefault(import('../src/pages/customer/courses/_id/index.vue' /* webpackChunkName: "pages/customer/courses/_id/index" */))
const _6345c531 = () => interopDefault(import('../src/pages/customer/managers-chat/_id/index.vue' /* webpackChunkName: "pages/customer/managers-chat/_id/index" */))
const _d1f830fc = () => interopDefault(import('../src/pages/customer/p/_id/index.vue' /* webpackChunkName: "pages/customer/p/_id/index" */))
const _78b85e84 = () => interopDefault(import('../src/pages/customer/product/_id.vue' /* webpackChunkName: "pages/customer/product/_id" */))
const _1d853a01 = () => interopDefault(import('../src/pages/customer/publications/_id/index.vue' /* webpackChunkName: "pages/customer/publications/_id/index" */))
const _6932ed75 = () => interopDefault(import('../src/pages/customer/questionnaire/_id/index.vue' /* webpackChunkName: "pages/customer/questionnaire/_id/index" */))
const _2be35af8 = () => interopDefault(import('../src/pages/customer/showcases/_id/index.vue' /* webpackChunkName: "pages/customer/showcases/_id/index" */))
const _041ea8b9 = () => interopDefault(import('../src/pages/customer/tickets/_id/index.vue' /* webpackChunkName: "pages/customer/tickets/_id/index" */))
const _4255afa8 = () => interopDefault(import('../src/pages/customer/courses/_id/progress/index.vue' /* webpackChunkName: "pages/customer/courses/_id/progress/index" */))
const _a7d9879a = () => interopDefault(import('../src/pages/customer/showcases/_id/_slug/index.vue' /* webpackChunkName: "pages/customer/showcases/_id/_slug/index" */))
const _cc2f2c14 = () => interopDefault(import('../src/pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bonuses",
    component: _414e99b1,
    children: [{
      path: "",
      component: _61995118,
      name: "bonuses"
    }]
  }, {
    path: "/cabinet",
    component: _2ceb9202,
    name: "cabinet"
  }, {
    path: "/courses",
    component: _21787f42,
    name: "courses"
  }, {
    path: "/login-as-claim",
    component: _f6211a5e,
    name: "login-as-claim"
  }, {
    path: "/managers-chat",
    component: _458e8574,
    name: "managers-chat"
  }, {
    path: "/notifications",
    component: _45899c8f,
    name: "notifications"
  }, {
    path: "/offers",
    component: _f82a2aba,
    children: [{
      path: "",
      component: _be10aa34,
      name: "offers"
    }, {
      path: "-OffersFilters",
      component: _33558196,
      name: "offers--OffersFilters"
    }, {
      path: "-OffersHintModal",
      component: _6398a77e,
      name: "offers--OffersHintModal"
    }, {
      path: "-OffersMobileTable",
      component: _4f57c6b2,
      name: "offers--OffersMobileTable"
    }, {
      path: "-OffersPeriod",
      component: _40ce3948,
      name: "offers--OffersPeriod"
    }, {
      path: ":id",
      component: _2c94e2d1,
      name: "offers-id"
    }]
  }, {
    path: "/postbacks",
    component: _bba7771a,
    name: "postbacks"
  }, {
    path: "/profile",
    component: _8969a5e0,
    name: "profile"
  }, {
    path: "/publications",
    component: _8ed9cbd4,
    name: "publications"
  }, {
    path: "/starter",
    component: _93960714,
    name: "starter"
  }, {
    path: "/statistics",
    component: _db40aba2,
    children: [{
      path: "",
      component: _86ecdd1c,
      name: "statistics"
    }, {
      path: "-StatisticsColumns",
      component: _5195bf60,
      name: "statistics--StatisticsColumns"
    }, {
      path: "-StatisticsFilters",
      component: _7a0e8404,
      name: "statistics--StatisticsFilters"
    }, {
      path: "-StatisticsGrouping",
      component: _7227c0b6,
      name: "statistics--StatisticsGrouping"
    }, {
      path: "-StatisticsPeriod",
      component: _cc2f0c18,
      name: "statistics--StatisticsPeriod"
    }, {
      path: "-StatisticsTable",
      component: _0eaaf411,
      name: "statistics--StatisticsTable"
    }]
  }, {
    path: "/tickets",
    component: _2a3eb664,
    name: "tickets"
  }, {
    path: "/auth/login",
    component: _690305ce,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _4d54cbb0,
    name: "auth-success"
  }, {
    path: "/cabinet/-CabinetCloseWithdrawalModal",
    component: _6411c65e,
    name: "cabinet--CabinetCloseWithdrawalModal"
  }, {
    path: "/cabinet/-IndividualEntrepreneurForm",
    component: _409ef733,
    name: "cabinet--IndividualEntrepreneurForm"
  }, {
    path: "/cabinet/-LegalEntityForm",
    component: _35687a59,
    name: "cabinet--LegalEntityForm"
  }, {
    path: "/cabinet/natural-person",
    component: _6573d01f,
    name: "cabinet-natural-person"
  }, {
    path: "/cabinet/self-employed",
    component: _6a10a97e,
    name: "cabinet-self-employed"
  }, {
    path: "/cabinet/withdrawals",
    component: _efc5fde4,
    name: "cabinet-withdrawals"
  }, {
    path: "/courses/categories",
    component: _25ecc122,
    name: "courses-categories"
  }, {
    path: "/request/fail",
    component: _5e118c2d,
    name: "request-fail"
  }, {
    path: "/request/sucess",
    component: _4ecad033,
    name: "request-sucess"
  }, {
    path: "/showcases/categories",
    component: _13792a34,
    name: "showcases-categories"
  }, {
    path: "/tickets/new",
    component: _28028dc2,
    name: "tickets-new"
  }, {
    path: "/cabinet/withdrawals/act/precalculation",
    component: _9b926ccc,
    name: "cabinet-withdrawals-act-precalculation"
  }, {
    path: "/cabinet/withdrawals/act/:id",
    component: _7523ef88,
    name: "cabinet-withdrawals-act-id"
  }, {
    path: "/cabinet/new-withdrawal/:id",
    component: _372ebd53,
    name: "cabinet-new-withdrawal-id"
  }, {
    path: "/courses/categories/:categoryId",
    component: _e8bb0faa,
    name: "courses-categories-categoryId"
  }, {
    path: "/showcases/categories/:categoryalias",
    component: _587e1f38,
    name: "showcases-categories-categoryalias"
  }, {
    path: "/showcases/categories/:categoryalias/subcategories",
    component: _5e91ae1e,
    name: "showcases-categories-categoryalias-subcategories"
  }, {
    path: "/showcases/categories/:categoryalias/subcategories/:subcategoryalias",
    component: _9fc3765a,
    name: "showcases-categories-categoryalias-subcategories-subcategoryalias"
  }, {
    path: "/articles/:id",
    component: _03fefbf7,
    name: "articles-id"
  }, {
    path: "/cabinet/:id",
    component: _db3c8b2c,
    name: "cabinet-id"
  }, {
    path: "/courses/:id",
    component: _b910d86c,
    name: "courses-id"
  }, {
    path: "/managers-chat/:id",
    component: _6345c531,
    name: "managers-chat-id"
  }, {
    path: "/p/:id",
    component: _d1f830fc,
    name: "p-id"
  }, {
    path: "/product/:id?",
    component: _78b85e84,
    name: "product-id"
  }, {
    path: "/publications/:id",
    component: _1d853a01,
    name: "publications-id"
  }, {
    path: "/questionnaire/:id",
    component: _6932ed75,
    name: "questionnaire-id"
  }, {
    path: "/showcases/:id",
    component: _2be35af8,
    name: "showcases-id"
  }, {
    path: "/tickets/:id",
    component: _041ea8b9,
    name: "tickets-id"
  }, {
    path: "/courses/:id/progress",
    component: _4255afa8,
    name: "courses-id-progress"
  }, {
    path: "/showcases/:id?/:slug",
    component: _a7d9879a,
    name: "showcases-id-slug"
  }, {
    path: "/",
    component: _cc2f2c14,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
