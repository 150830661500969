











import { FormatNumberOptions, formatNumber } from '@/composables/utils'
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        available: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        }
    },
    setup: (props) => {
        const formatParams: FormatNumberOptions = {
            trimFracts: true,
            groupSeparator: '&nbsp;'
        }

        const formattedAvailable = computed(() => formatNumber(props.available, formatParams))
        const formattedTotal = computed(() => formatNumber(props.available, formatParams))

        return {
            formattedAvailable,
            formattedTotal
        }
    }
})
