

























































































import { defineComponent, computed, onMounted } from '@nuxtjs/composition-api'
import { user, userFullName } from '@/composables/user'
import { httpLinkRegExp } from '@/composables/regexps'
import { generalizeLinkImage } from '@/composables/images'
import { useDeviceWatcher } from '@/composables/device-watcher'

import AppAvatar from '@/components/ui/AppAvatar.vue'

import HeaderUnreadButton from './-HeaderUnreadButton.vue'
import HeaderBalance from './-HeaderBalance.vue'

export default defineComponent({
    components: {
        HeaderUnreadButton,
        HeaderBalance
    },
    props: {
        ...AppAvatar.props,
        ...HeaderUnreadButton.props,
        open: {
            type: Boolean,
            default: false
        },
        profileLink: {
            type: String,
            default: '/profile'
        }
    },
    setup (props) {
        const {
            attach,
            currentDevice
        } = useDeviceWatcher([
            { alias: 'mobile', breakpoint: 0 },
            { alias: 'desktop', breakpoint: 1024 }
        ])

        const linkType = computed(() => {
            if (httpLinkRegExp.test(props.profileLink || '')) {
                return 'a'
            }

            return 'nuxt-link'
        })

        const profileImage = computed(() => (props.image ? generalizeLinkImage(props.image) : ''))

        onMounted(() => {
            attach()
        })

        return {
            currentDevice,
            userFullName,
            linkType,
            profileImage,
            user
        }
    }
})
