











import {
    computed,
    defineComponent,
    onMounted,
    useContext,
    watch
} from '@nuxtjs/composition-api'
import { formatNumber, FormatNumberOptions } from '@/composables/utils'
import { fetchBalances, balance } from '@/composables/cabinet-balance'

export default defineComponent({
    props: {
        total: {
            type: Number,
            default: 0
        }
    },
    setup () {
        const context = useContext()
        const { route } = context

        const formatParams: FormatNumberOptions = {
            trimFracts: true,
            groupSeparator: '&nbsp;'
        }

        const formattedAvailable = computed(
            () => formatNumber(balance.value.currentAmount, formatParams)
        )

        onMounted(async () => {
            balance.value = await fetchBalances(context)
        })

        watch(
            () => route.value,
            () => { fetchBalances(context) }
        )

        onMounted(async () => {
            await fetchBalances(context)
        })

        return {
            formattedAvailable
        }
    }
})
