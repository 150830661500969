




























import {
    defineComponent, PropType,
    ref,
    useContext, useRouter
} from "@nuxtjs/composition-api";

import SuitcaseIcon from '@/assets/img/suitcase.svg'
import ChevronIcon from '@/assets/img/chevron.svg'
import QuestionIcon from '@/assets/img/question-circle.svg'

import { closeAside } from "@/composables/asides";

import { CatalogItem as CatalogItemInterface } from "@/interfaces/catalog.interface";
import { NavigationItem } from "@/interfaces/navigation.interface";

export default defineComponent({
    components: {
        SuitcaseIcon,
        ChevronIcon,
        QuestionIcon
    },
    props: {
        catalogItems: {
            type: Array as PropType<CatalogItemInterface[]>,
            default: () => []
        },
        navigation: {
            type: Array as PropType<NavigationItem[]>,
            required: true
        },
        aside: {
            type: Boolean,
            default: false
        }
    },
    setup: () => {
        const context = useContext()
        const router = useRouter()
        const openedLinks = ref<string[]>([])

        const isNestedLinksOpened = (linkAlias: string | undefined) => {
            if (!linkAlias) return undefined

            return openedLinks.value.includes(linkAlias)
        }

        const onToggleLink = (linkAlias: string | undefined) => {
            if (!linkAlias) return undefined

            if (openedLinks.value.includes(linkAlias)) {
                openedLinks.value = openedLinks.value.filter(link => link !== linkAlias)
            } else {
                openedLinks.value.push(linkAlias)
            }
        }

        const openLink = (url: string | undefined) => {
            if (!url || !url.trim() || url === '/p/tool-pages') return undefined

            router.push(url)
        }

        const toggleAside = (link: NavigationItem) => {
            if (!link.nested) closeAside()
        }

        return {
            openLink,
            isNestedLinksOpened,
            onToggleLink,
            toggleAside
        }
    }
})
