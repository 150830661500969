







































import {
    defineComponent,
    useContext,
    useRoute,
    useRouter
} from "@nuxtjs/composition-api";

import SuitcaseIcon from '@/assets/img/suitcase.svg'
import ChevronIcon from '@/assets/img/chevron.svg'
import HeaderBalance from "@/components/pages/TheHeader/-HeaderBalance.vue";

import {
    selectedCabinetId,
    selectedCabinetName,
    cabinetTypesOptions,
    changeCurrentCabinet
} from "@/composables/cabinet-type";

export default defineComponent({
    components: {
        HeaderBalance,
        SuitcaseIcon,
        ChevronIcon
    },
    setup: () => {
        const context = useContext()
        const route = useRoute()
        const router = useRouter()

        const changeCurrentCabinetId = async (cabinetId: string) => {
            await changeCurrentCabinet(cabinetId, context)

            if (route.value.fullPath.includes('/p/')) {
                router.go(0)
            }
        }

        return {
            cabinetTypesOptions,
            selectedCabinetId,
            selectedCabinetName,
            changeCurrentCabinetId
        }
    }
})
