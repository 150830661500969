import { Components as Customer } from '@/types/client.customer'
import { user } from '@/composables/user'
import { ref } from '@nuxtjs/composition-api'

import { useSinglePromise } from '@/composables/single-promise'
import { Context } from '@nuxt/types'

export type ExtendedCabinetType = Customer.Schemas.ExecutorCabinetType | 'SelfEmployed'

type CabinetTypeInfo = {
    [key in ExtendedCabinetType]: string
}

export const cabinetTypeDescription: CabinetTypeInfo = {
    IndividualEntrepreneur: 'ИП',
    LegalEntity: 'Организация',
    NaturalPerson: 'Физическое лицо',
    SelfEmployed: 'Самозанятый',
    Unknown: ''
}

export const сabinetTypePrefixes: CabinetTypeInfo = {
    IndividualEntrepreneur: 'ИП',
    LegalEntity: 'ООО',
    NaturalPerson: 'ФЛ',
    SelfEmployed: 'СМЗ',
    Unknown: ''
}

export const userCabinets = ref<Customer.Schemas.ExecutorCabinetIdWithTypeAndDescription[]>([])
export const selectedCabinetId = ref<string| null | undefined>(null)
export const selectedCabinetName = ref<string| null | undefined>('Кабинет')
export const cabinetTypesOptions = ref<{ id: string; text: string }[]>([])

export const getCabinetTypes = useSinglePromise(async (context: unknown) => {
    const { $apiCustomer } = context as Context

    const { data } = await $apiCustomer.executorCabinetsGetUserCabinets()
    userCabinets.value = data
    cabinetTypesOptions.value = data.map(type => ({
            id: type.id || '',
            text: type.description || ''
        }))
})

export const getSelectedCabinet = () => {
    selectedCabinetId.value = user.value.cabinet?.currentCabinetId
    selectedCabinetName.value = userCabinets.value
        .find(cabinet => cabinet.id === selectedCabinetId.value)
        ?.description
        || 'Кабинет'
}

export const changeCurrentCabinet = useSinglePromise(async (
    cabinetId: string,
    context: unknown
) => {
    const { $apiCustomer } = context as Context
    const selectedCabinet = userCabinets.value
        .find(cabinet => cabinet.id === cabinetId)

    await $apiCustomer.executorCabinetsSetUserCurrentExecutorCabinet(null, {
        cabinetId: selectedCabinet?.id,
        executorCabinetType: selectedCabinet?.type
    })

    selectedCabinetId.value = cabinetId
    selectedCabinetName.value = selectedCabinet?.description
})
