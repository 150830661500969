import moment from 'moment'

export enum SortByTypes {
    Descending = 'Descending',
    Ascending = 'Ascending'
}

export const sortNumbers = (a: number, b: number, sortBy: SortByTypes) => (sortBy === SortByTypes.Descending
        ? a - b
        : b - a)

export const sortStrings = (a: string, b: string, sortBy: SortByTypes) => {
    if (!a) return -1
    if (!b) return 1

    return sortBy === SortByTypes.Descending
        ? a.localeCompare(b, undefined, { numeric: true })
        : b.localeCompare(a, undefined, { numeric: true })
}

export const sortDates = (
    a: moment.Moment | Date | string,
    b: moment.Moment | Date | string,
    sortBy: SortByTypes
) => (sortBy === SortByTypes.Descending
        ? moment(a).diff(moment(b))
        : moment(b).diff(moment(a)))

type PrioprityList<T extends string | number | symbol> = {
    [key in T]: number
}
type Clear<T> = Exclude<Exclude<T, undefined>, null>
export const sortByPriority = <T extends string | number | symbol | undefined | null>(
    a: T,
    b: T,
    priority: PrioprityList<Clear<T>>,
    sortBy: SortByTypes = SortByTypes.Ascending
) => {
    const aPriority = priority[a as Clear<T>] || Infinity
    const bPriority = priority[b as Clear<T>] || Infinity

    return (sortBy === SortByTypes.Ascending
        ? (aPriority - bPriority)
        : (bPriority - aPriority)
    ) || 0
}
