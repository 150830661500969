import { computed, ref } from '@nuxtjs/composition-api'
import { useCabinets } from '@/composables/cabinets'

export interface Balance {
    currentAmount: number,
    currentBonuses: number,
    availableAmount: number,
    availableBonuses: number
}

export const balance = ref<Balance>({
    currentAmount: 0,
    currentBonuses: 0,
    availableAmount: 0,
    availableBonuses: 0
})

export const fetchBalances = async (context: unknown) => {
    const { fetchCabinets } = useCabinets(context)

    const cabinets = await fetchCabinets()

    const balances = computed(() => cabinets?.reduce((total, cabinet) => {
        if (cabinet.type === 'NaturalPerson') {
            cabinet.accounts?.forEach((account) => {
                total.currentAmount += account.balance?.requestsAmount || 0
                total.availableAmount += account.balance?.requestsAvailableAmount || 0
            })
        } else if (cabinet.type === 'SelfEmployed') {
            cabinet.accounts?.forEach((account) => {
                total.currentAmount += account.balance?.bonusesAmount || 0
                total.currentBonuses += account.balance?.bonusesAmount || 0
                total.availableAmount += account.balance?.bonusesAvailableAmount || 0
                total.availableBonuses += account.balance?.bonusesAvailableAmount || 0
            })
        } else {
            cabinet.accounts?.forEach((account) => {
                total.currentAmount += account.balance?.currentAmount || 0
                total.currentBonuses += account.balance?.bonusesAmount || 0
                total.availableAmount += account.balance?.availableAmount || 0
                total.availableBonuses += account.balance?.bonusesAvailableAmount || 0
            })
        }

        return total
    }, {
        currentAmount: 0,
        currentBonuses: 0,
        availableAmount: 0,
        availableBonuses: 0
    }))

    return balances.value
}
