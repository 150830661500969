var render = function () {
  var _vm$user$profile2, _vm$currentDevice;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-profile"
  }, [_c('app-tooltip', {
    staticClass: "header-profile__tooltip",
    attrs: {
      "placement": "bottom",
      "hoverable": "",
      "offset": 20
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (defaultScope) {
        return [_vm._t("icon", function () {
          return [_c(_vm.linkType, {
            tag: "component",
            staticClass: "header-profile__avatar-link",
            attrs: {
              "to": _vm.profileLink,
              "href": _vm.profileLink
            }
          }, [_c('app-avatar', {
            staticClass: "header-profile__avatar-img",
            attrs: {
              "name": _vm.userFullName,
              "image": _vm.profileImage
            }
          })], 1)];
        }, null, defaultScope)];
      }
    }, {
      key: "tooltip",
      fn: function () {
        var _vm$user$profile, _vm$user$id;

        return [_c('div', {
          staticClass: "header-profile__tooltip-info"
        }, [_c(_vm.linkType, {
          tag: "component",
          staticClass: "header-profile__name",
          attrs: {
            "to": _vm.profileLink,
            "href": _vm.profileLink
          }
        }, [_vm._v("\n                    " + _vm._s(((_vm$user$profile = _vm.user.profile) === null || _vm$user$profile === void 0 ? void 0 : _vm$user$profile.name) || 'Пользователь') + "\n                ")]), _vm._v(" "), _c('header-balance')], 1), _vm._v(" "), _c('div', {
          staticClass: "header-profile__tooltip-id"
        }, [_c('app-copy', {
          staticClass: "header-profile__tooltip-id-copy",
          attrs: {
            "no-icon": "",
            "to-copy": _vm.user.id
          }
        }, [_vm._v("\n                    ID\n                    "), _c('span', {
          staticClass: "header-profile__tooltip-id-copy-item"
        }, [_vm._v("\n                        ..." + _vm._s(((_vm$user$id = _vm.user.id) === null || _vm$user$id === void 0 ? void 0 : _vm$user$id.slice(-6)) || '') + "\n                    ")])]), _vm._v(" "), _c('app-hint', {
          staticClass: "header-profile__tooltip-id-hint",
          attrs: {
            "hoverable": "",
            "placement": "bottom"
          }
        }, [_vm._v("\n                    Идентификационный номер. Нужен при обращении в службу поддержки.\n                ")])], 1)];
      },
      proxy: true
    }], null, true)
  }), _vm._v(" "), _c('header-unread-button', {
    staticClass: "header-profile__avatar",
    attrs: {
      "minimal": "",
      "unread-count": _vm.unreadCount
    },
    on: {
      "on-click": function () {
        return _vm.$emit(_vm.open ? 'on-close' : 'on-open');
      }
    }
  }, [_c('app-avatar', {
    class: {
      'header-profile__avatar-img': true,
      'header-profile__avatar-img--open': _vm.open
    },
    attrs: {
      "name": _vm.name,
      "image": _vm.profileImage
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "header-profile__info"
  }, [_c(_vm.linkType, {
    tag: "component",
    staticClass: "header-profile__name",
    attrs: {
      "to": _vm.profileLink,
      "href": _vm.profileLink
    }
  }, [_vm._v("\n            " + _vm._s(((_vm$user$profile2 = _vm.user.profile) === null || _vm$user$profile2 === void 0 ? void 0 : _vm$user$profile2.name) || 'Пользователь') + "\n        ")]), _vm._v(" "), ((_vm$currentDevice = _vm.currentDevice) === null || _vm$currentDevice === void 0 ? void 0 : _vm$currentDevice.alias) === 'desktop' ? _c('header-balance') : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }